import React from 'react';
import './Logo.css'; // Import the CSS file for the logo
import './Logo1.css'; // Import the CSS file for the logo


const Logo = () => {
  return (
    <div className="logo">
    </div>
  );
};

export default Logo;
